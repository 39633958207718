import React from "react"
import { NewHeader } from "../components/Headers/NewHeader"
import Layout from "../components/Layouts/secondaryPage"
import LongFormSuccess from "../components/LongFormSuccessNotCourier/LongFormSuccess"

export default function VerifyPage() {
  return (
    <Layout>
      <NewHeader redLogoWithSovcombank noRightSection noShadow={false} />
      <LongFormSuccess />
    </Layout>
  )
}

import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Container from "@material-ui/core/Container"
import CircleContainer from "../CircleContainer/CircleContainer"

import * as styles from "./longformsuccess.module.scss"

export default function LongFormSuccess() {
  return (
    <div className="bg-wrapper">
      <CircleContainer className={styles.containerfluid}>
        <Container className={styles.maincontainer}>
          <CircleContainer className={styles.container}>
            <h1 className={styles.head}>
              Мы приняли вашу <br />
              заявку и свяжемся <br />
              с вами в ближайшее <br />
              время
            </h1>
            <div className={styles.descriptionHead}>
              Доставка курьером в вашем городе пока <br /> недоступна
            </div>
          </CircleContainer>
          <CircleContainer className={styles.containersecond}>
            <div className={styles.description}>
              А&nbsp;пока&nbsp;загляните&nbsp;в&nbsp;наш&nbsp;магазин&nbsp;— нам есть чем вас
              порадовать
            </div>
            <div>
              <StaticImage
                className={styles.imgcarta}
                alt="halva-mir"
                src="./img/halva-mir.png"
                placeholder="blurred"
                formats={["auto", "webp", "avif"]}
              />
            </div>
            <a
              href="https://halvacard.ru/"
              target="_blank"
              className={styles.productsbutton}
              rel="noreferrer"
            >
              Посмотреть товары
            </a>
          </CircleContainer>
        </Container>
      </CircleContainer>
    </div>
  )
}

// extracted by mini-css-extract-plugin
export var back = "longformsuccess-module--back--rjhcn";
export var buttonback = "longformsuccess-module--buttonback--cU-DJ";
export var container = "longformsuccess-module--container--xgT2I";
export var containerfluid = "longformsuccess-module--containerfluid--GzmUH";
export var containersecond = "longformsuccess-module--containersecond--QZ4Rd";
export var description = "longformsuccess-module--description--VciSD";
export var descriptionHead = "longformsuccess-module--descriptionHead--CCix0";
export var head = "longformsuccess-module--head--iVB60";
export var imgcarta = "longformsuccess-module--imgcarta--2P6iX";
export var maincontainer = "longformsuccess-module--maincontainer--FVtN-";
export var productsbutton = "longformsuccess-module--productsbutton--GtbA4";